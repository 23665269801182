import { DeploymentEnvironmentName } from "./types";

export enum EnvironmentVariableKeys {
  REACT_APP_ADMIN_WEB_APP_URL = "REACT_APP_ADMIN_WEB_APP_URL",
  REACT_APP_API_MAIN_URL = "REACT_APP_API_MAIN_URL",
  REACT_APP_ATTENDANCE_SCORE_SERVICE_URL = "REACT_APP_ATTENDANCE_SCORE_SERVICE_URL",
  REACT_APP_BASE_API_URL = "REACT_APP_BASE_API_URL",
  REACT_APP_BFF_URL = "REACT_APP_BFF_URL",
  REACT_APP_BITRISE_BUILD_NUMBER = "REACT_APP_BITRISE_BUILD_NUMBER",
  REACT_APP_DATADOG_RUM_APPLICATION_ID = "REACT_APP_DATADOG_RUM_APPLICATION_ID",
  REACT_APP_DATADOG_RUM_CLIENT_TOKEN = "REACT_APP_DATADOG_RUM_CLIENT_TOKEN",
  REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION = "REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION",
  REACT_APP_DOCUMENTS_SERVICE_URL = "REACT_APP_DOCUMENTS_SERVICE_URL",
  REACT_APP_ENVIRONMENT_NAME = "REACT_APP_ENVIRONMENT_NAME",
  REACT_APP_FIREBASE_BUNDLE_ID = "REACT_APP_FIREBASE_BUNDLE_ID",
  REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN = "REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN",
  REACT_APP_GOOGLE_MAP_API = "REACT_APP_GOOGLE_MAP_API",
  REACT_APP_HCP_WEB_BRAZE_KEY = "REACT_APP_HCP_WEB_BRAZE_KEY",
  REACT_APP_HCP_WEB_BRAZE_SDK_ENDPOINT = "REACT_APP_HCP_WEB_BRAZE_SDK_ENDPOINT",
  REACT_APP_HOME_HEALTH_API_URL = "REACT_APP_HOME_HEALTH_API_URL",
  REACT_APP_IDENTITY_DOC_AUTOVERIFICATION_SERVICE_API_URL = "REACT_APP_IDENTITY_DOC_AUTOVERIFICATION_SERVICE_API_URL",
  REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID = "REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID",
  REACT_APP_LICENSE_MANAGER_SERVICE_URL = "REACT_APP_LICENSE_MANAGER_SERVICE_URL",
  REACT_APP_LOCATION_SERVICE_URL = "REACT_APP_LOCATION_SERVICE_URL",
  REACT_APP_MUI_LICENSE_KEY = "REACT_APP_MUI_LICENSE_KEY",
  REACT_APP_PAYMENTS_API_URL = "REACT_APP_PAYMENTS_API_URL",
  REACT_APP_RADAR_SDK_PUBLISHABLE_KEY = "REACT_APP_RADAR_SDK_PUBLISHABLE_KEY",
  REACT_APP_SEGMENT_ANDROID_KEY = "REACT_APP_SEGMENT_ANDROID_KEY",
  REACT_APP_SEGMENT_IOS_KEY = "REACT_APP_SEGMENT_IOS_KEY",
  REACT_APP_SEGMENT_KEY = "REACT_APP_SEGMENT_KEY",
  REACT_APP_SEGMENT_WEB_KEY = "REACT_APP_SEGMENT_WEB_KEY",
  REACT_APP_SENDBIRD_APP_ID = "REACT_APP_SENDBIRD_APP_ID",
  REACT_APP_SHIFT_REVIEW_API_URL = "REACT_APP_SHIFT_REVIEW_API_URL",
  REACT_APP_STRIPE_PUBLIC_KEY = "REACT_APP_STRIPE_PUBLIC_KEY",
  REACT_APP_URGENT_SHIFTS_SERVICE_URL = "REACT_APP_URGENT_SHIFTS_SERVICE_URL",
  REACT_APP_UX_CAM_KEY = "REACT_APP_UX_CAM_KEY",
  REACT_APP_VERSION_NAME = "REACT_APP_VERSION_NAME",
  REACT_APP_WEB_APP_URL = "REACT_APP_WEB_APP_URL",
  REACT_APP_WORKER_SERVICE_URL = "REACT_APP_WORKER_SERVICE_URL",
  REACT_APP_ZENDESK_JWT_MICROSERVICE_BASE_URL = "REACT_APP_ZENDESK_JWT_MICROSERVICE_BASE_URL",
  REACT_APP_ZENDESK_MESSAGING_WIDGET_URL = "REACT_APP_ZENDESK_MESSAGING_WIDGET_URL",
  REACT_APP_ZENDESK_SDK_CHANNEL_KEY_ANDROID = "REACT_APP_ZENDESK_SDK_CHANNEL_KEY_ANDROID",
  REACT_APP_ZENDESK_SDK_CHANNEL_KEY_IOS = "REACT_APP_ZENDESK_SDK_CHANNEL_KEY_IOS",
  REACT_APP_ZENDESK_WEB_WIDGET_ID = "REACT_APP_ZENDESK_WEB_WIDGET_ID",
}

type Environments = Record<DeploymentEnvironmentName, Record<EnvironmentVariableKeys, string>>;

const environmentConfigs: Environments = {
  [DeploymentEnvironmentName.LOCAL]: {
    REACT_APP_ADMIN_WEB_APP_URL:
      process.env.REACT_APP_ADMIN_WEB_APP_URL ??
      "https://admin-webapp.development.clipboardhealth.org",
    REACT_APP_API_MAIN_URL: process.env.REACT_APP_API_MAIN_URL ?? "http://localhost:5000",
    REACT_APP_ATTENDANCE_SCORE_SERVICE_URL:
      process.env.REACT_APP_ATTENDANCE_SCORE_SERVICE_URL ??
      "https://apigateway.development.clipboardhealth.org/attendance-policy",
    REACT_APP_BASE_API_URL: process.env.REACT_APP_BASE_API_URL ?? "http://localhost:5000/api",
    REACT_APP_BFF_URL:
      process.env.REACT_APP_BFF_URL ??
      "https://apigateway.development.clipboardhealth.org/shifts-bff",
    REACT_APP_BITRISE_BUILD_NUMBER: process.env.REACT_APP_BITRISE_BUILD_NUMBER ?? "",
    REACT_APP_DATADOG_RUM_APPLICATION_ID:
      process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID ?? "2681f1ca-aba9-4a8e-9b52-ae4e6deb4ad3",
    REACT_APP_DATADOG_RUM_CLIENT_TOKEN:
      process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN ?? "pub88f32af388220deaa1416ca702156f46",
    REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION:
      process.env.REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION ?? "",
    REACT_APP_DOCUMENTS_SERVICE_URL:
      process.env.REACT_APP_DOCUMENTS_SERVICE_URL ??
      "https://apigateway.development.clipboardhealth.org/docs/graphql",
    REACT_APP_ENVIRONMENT_NAME:
      process.env.REACT_APP_ENVIRONMENT_NAME ?? DeploymentEnvironmentName.DEVELOPMENT,
    REACT_APP_FIREBASE_BUNDLE_ID:
      process.env.REACT_APP_FIREBASE_BUNDLE_ID ?? "health.clipboard.worker",
    REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN:
      process.env.REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN ?? "clipboard.page.link",
    REACT_APP_GOOGLE_MAP_API:
      process.env.REACT_APP_GOOGLE_MAP_API ?? "AIzaSyC8W_0rbJCPhUHl0pf0XkWC59R4eHGpSmA",
    REACT_APP_HOME_HEALTH_API_URL:
      process.env.REACT_APP_HOME_HEALTH_API_URL ??
      "https://home-health-api.development.clipboardhealth.org/api",
    REACT_APP_HCP_WEB_BRAZE_KEY:
      process.env.REACT_APP_HCP_WEB_BRAZE_KEY ?? "4da001ab-e194-4cd4-a175-c73f172d8a57",
    REACT_APP_HCP_WEB_BRAZE_SDK_ENDPOINT:
      process.env.REACT_APP_HCP_WEB_BRAZE_SDK_ENDPOINT ?? "sdk.iad-05.braze.com",
    REACT_APP_IDENTITY_DOC_AUTOVERIFICATION_SERVICE_API_URL:
      process.env.REACT_APP_IDENTITY_DOC_AUTOVERIFICATION_SERVICE_API_URL ??
      "https://identity-doc-autoverification-service.development.clipboardhealth.org/api",
    REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID:
      process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? "630f2bba0ae37a11f535a61e",
    REACT_APP_LICENSE_MANAGER_SERVICE_URL:
      process.env.REACT_APP_LICENSE_MANAGER_SERVICE_URL ??
      "https://apigateway.development.clipboardhealth.org/license-manager",
    REACT_APP_LOCATION_SERVICE_URL:
      process.env.REACT_APP_LOCATION_SERVICE_URL ??
      "https://apigateway.development.clipboardhealth.org/location",
    REACT_APP_MUI_LICENSE_KEY:
      process.env.REACT_APP_MUI_LICENSE_KEY ??
      "9d431ac8d7aa1f4b62a9d61432d0b4a1Tz03ODUyNCxFPTE3MzEyODg5NjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
    REACT_APP_PAYMENTS_API_URL:
      process.env.REACT_APP_PAYMENTS_API_URL ??
      "https://apigateway.development.clipboardhealth.org/payment",
    REACT_APP_RADAR_SDK_PUBLISHABLE_KEY:
      process.env.REACT_APP_RADAR_SDK_PUBLISHABLE_KEY ??
      "prj_test_pk_c3450bd48e786c9d835dc98922892915c94a3173",
    REACT_APP_SEGMENT_ANDROID_KEY:
      process.env.REACT_APP_SEGMENT_ANDROID_KEY ?? "pN53g0y3BuPEhQHED2HMHWDfRiPod2Sz",
    REACT_APP_SEGMENT_IOS_KEY:
      process.env.REACT_APP_SEGMENT_IOS_KEY ?? "kcntZufgUuUURpBEZUTXBC01Mg9hwV1k",
    REACT_APP_SEGMENT_KEY: process.env.REACT_APP_SEGMENT_KEY ?? "gEblLFFYPXkwJEvwiuE0KmGCbOS9t1vG",
    REACT_APP_SEGMENT_WEB_KEY:
      process.env.REACT_APP_SEGMENT_WEB_KEY ?? "504REOfZTAPHjRpK2TXCGlnbz9ieqPmj",
    REACT_APP_SENDBIRD_APP_ID:
      process.env.REACT_APP_SENDBIRD_APP_ID ?? "F35ACEA9-2248-44DC-A448-73DCCB3FC17E",
    REACT_APP_SHIFT_REVIEW_API_URL:
      process.env.REACT_APP_SHIFT_REVIEW_API_URL ??
      "https://apigateway.development.clipboardhealth.org/reviews",
    REACT_APP_STRIPE_PUBLIC_KEY:
      process.env.REACT_APP_STRIPE_PUBLIC_KEY ??
      "pk_test_51HrTYbCZ2LZg728WUekdiCOJFR8XRryOT2JKee8nxl15vMntnkZUQpIbEFTRNBGaHHQZjo4tWgC2LhUfbPTHDEg4000wEe7wGC",
    REACT_APP_URGENT_SHIFTS_SERVICE_URL:
      process.env.REACT_APP_URGENT_SHIFTS_SERVICE_URL ??
      "https://apigateway.development.clipboardhealth.org/urgent-shifts",
    REACT_APP_UX_CAM_KEY: process.env.REACT_APP_UX_CAM_KEY ?? "",
    REACT_APP_VERSION_NAME: process.env.REACT_APP_VERSION_NAME ?? "",
    REACT_APP_WEB_APP_URL:
      process.env.REACT_APP_WEB_APP_URL ?? "https://hcp-webapp.development.clipboardhealth.org",
    REACT_APP_WORKER_SERVICE_URL:
      process.env.REACT_APP_WORKER_SERVICE_URL ??
      "https://apigateway.development.clipboardhealth.org/worker",
    REACT_APP_ZENDESK_JWT_MICROSERVICE_BASE_URL:
      process.env.REACT_APP_ZENDESK_JWT_MICROSERVICE_BASE_URL ??
      "https://zendesk-jwt-service.scaffold.dev.clipboardstaffing.com",
    REACT_APP_ZENDESK_MESSAGING_WIDGET_URL:
      process.env.REACT_APP_ZENDESK_MESSAGING_WIDGET_URL ??
      "https://static.zdassets.com/ekr/snippet.js?key=9d3af7b3-f53a-4570-9dc5-77c7c46da7dc",
    REACT_APP_ZENDESK_SDK_CHANNEL_KEY_ANDROID:
      process.env.REACT_APP_ZENDESK_SDK_CHANNEL_KEY_ANDROID ??
      "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2NsaXBib2FyZGhlYWx0aDE2MzcyNjE4NTUuemVuZGVzay5jb20vbW9iaWxlX3Nka19hcGkvc2V0dGluZ3MvMDFHMlNQWVRSM0gwQVQzS0g1VDhNTVpYOTQuanNvbiJ9",
    REACT_APP_ZENDESK_SDK_CHANNEL_KEY_IOS:
      process.env.REACT_APP_ZENDESK_SDK_CHANNEL_KEY_IOS ??
      "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2NsaXBib2FyZGhlYWx0aDE2MzcyNjE4NTUuemVuZGVzay5jb20vbW9iaWxlX3Nka19hcGkvc2V0dGluZ3MvMDFHMlNQWVRSM0gwQVQzS0g1VDhNTVpYOTQuanNvbiJ9",
    REACT_APP_ZENDESK_WEB_WIDGET_ID: process.env.REACT_APP_ZENDESK_WEB_WIDGET_ID ?? "ze-snippet",
  },
  [DeploymentEnvironmentName.DEVELOPMENT]: {
    REACT_APP_ADMIN_WEB_APP_URL: "https://admin-webapp.development.clipboardhealth.org",
    REACT_APP_API_MAIN_URL: "https://apigateway.development.clipboardhealth.org",
    REACT_APP_ATTENDANCE_SCORE_SERVICE_URL:
      "https://apigateway.development.clipboardhealth.org/attendance-policy",
    REACT_APP_BASE_API_URL: "https://apigateway.development.clipboardhealth.org/api",
    REACT_APP_BFF_URL: "https://apigateway.development.clipboardhealth.org/shifts-bff",
    REACT_APP_BITRISE_BUILD_NUMBER: process.env.REACT_APP_BITRISE_BUILD_NUMBER ?? "",
    REACT_APP_DATADOG_RUM_APPLICATION_ID: "2681f1ca-aba9-4a8e-9b52-ae4e6deb4ad3",
    REACT_APP_DATADOG_RUM_CLIENT_TOKEN: "pub88f32af388220deaa1416ca702156f46",
    REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION:
      process.env.REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION ?? "",
    REACT_APP_DOCUMENTS_SERVICE_URL:
      "https://apigateway.development.clipboardhealth.org/docs/graphql",
    REACT_APP_ENVIRONMENT_NAME: DeploymentEnvironmentName.DEVELOPMENT,
    REACT_APP_FIREBASE_BUNDLE_ID: "health.clipboard.worker",
    REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN: "clipboard.page.link",
    REACT_APP_GOOGLE_MAP_API: "AIzaSyC8W_0rbJCPhUHl0pf0XkWC59R4eHGpSmA",
    REACT_APP_HOME_HEALTH_API_URL: "https://home-health-api.development.clipboardhealth.org/api",
    REACT_APP_HCP_WEB_BRAZE_KEY: "4da001ab-e194-4cd4-a175-c73f172d8a57",
    REACT_APP_HCP_WEB_BRAZE_SDK_ENDPOINT: "sdk.iad-05.braze.com",
    REACT_APP_IDENTITY_DOC_AUTOVERIFICATION_SERVICE_API_URL:
      "https://identity-doc-autoverification-service.development.clipboardhealth.org/api",
    REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID: "630f2bba0ae37a11f535a61e",
    REACT_APP_LICENSE_MANAGER_SERVICE_URL:
      "https://apigateway.development.clipboardhealth.org/license-manager",
    REACT_APP_LOCATION_SERVICE_URL: "https://apigateway.development.clipboardhealth.org/location",
    REACT_APP_MUI_LICENSE_KEY:
      "9d431ac8d7aa1f4b62a9d61432d0b4a1Tz03ODUyNCxFPTE3MzEyODg5NjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
    REACT_APP_PAYMENTS_API_URL: "https://apigateway.development.clipboardhealth.org/payment",
    REACT_APP_RADAR_SDK_PUBLISHABLE_KEY: "prj_test_pk_c3450bd48e786c9d835dc98922892915c94a3173",
    REACT_APP_SEGMENT_ANDROID_KEY: "pN53g0y3BuPEhQHED2HMHWDfRiPod2Sz",
    REACT_APP_SEGMENT_IOS_KEY: "kcntZufgUuUURpBEZUTXBC01Mg9hwV1k",
    REACT_APP_SEGMENT_KEY: "gEblLFFYPXkwJEvwiuE0KmGCbOS9t1vG",
    REACT_APP_SEGMENT_WEB_KEY: "504REOfZTAPHjRpK2TXCGlnbz9ieqPmj",
    REACT_APP_SENDBIRD_APP_ID: "F35ACEA9-2248-44DC-A448-73DCCB3FC17E",
    REACT_APP_SHIFT_REVIEW_API_URL: "https://apigateway.development.clipboardhealth.org/reviews",
    REACT_APP_STRIPE_PUBLIC_KEY:
      "pk_test_51HrTYbCZ2LZg728WUekdiCOJFR8XRryOT2JKee8nxl15vMntnkZUQpIbEFTRNBGaHHQZjo4tWgC2LhUfbPTHDEg4000wEe7wGC",
    REACT_APP_URGENT_SHIFTS_SERVICE_URL:
      "https://apigateway.development.clipboardhealth.org/urgent-shifts",
    REACT_APP_UX_CAM_KEY: "",
    REACT_APP_VERSION_NAME: process.env.REACT_APP_VERSION_NAME ?? "",
    REACT_APP_WEB_APP_URL: "https://hcp-webapp.development.clipboardhealth.org",
    REACT_APP_WORKER_SERVICE_URL: "https://apigateway.development.clipboardhealth.org/worker",
    REACT_APP_ZENDESK_JWT_MICROSERVICE_BASE_URL:
      "https://zendesk-jwt-service.scaffold.dev.clipboardstaffing.com",
    REACT_APP_ZENDESK_MESSAGING_WIDGET_URL:
      "https://static.zdassets.com/ekr/snippet.js?key=704277c1-a38e-4cc9-aa16-f535a75e1fad",
    REACT_APP_ZENDESK_SDK_CHANNEL_KEY_ANDROID:
      "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2NsaXBib2FyZGhlYWx0aDE2MzcyNjE4NTUuemVuZGVzay5jb20vbW9iaWxlX3Nka19hcGkvc2V0dGluZ3MvMDFHMlNQWVRSM0gwQVQzS0g1VDhNTVpYOTQuanNvbiJ9",
    REACT_APP_ZENDESK_SDK_CHANNEL_KEY_IOS:
      "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2NsaXBib2FyZGhlYWx0aDE2MzcyNjE4NTUuemVuZGVzay5jb20vbW9iaWxlX3Nka19hcGkvc2V0dGluZ3MvMDFHMlNQWVRSM0gwQVQzS0g1VDhNTVpYOTQuanNvbiJ9",
    REACT_APP_ZENDESK_WEB_WIDGET_ID: "ze-snippet",
  },
  [DeploymentEnvironmentName.STAGING]: {
    REACT_APP_ADMIN_WEB_APP_URL: "https://admin-webapp.staging.clipboardhealth.org",
    REACT_APP_API_MAIN_URL: "https://apigateway.staging.clipboardhealth.org",
    REACT_APP_ATTENDANCE_SCORE_SERVICE_URL:
      "https://apigateway.staging.clipboardhealth.org/attendance-policy",
    REACT_APP_BASE_API_URL: "https://apigateway.staging.clipboardhealth.org/api",
    REACT_APP_BFF_URL: "https://apigateway.staging.clipboardhealth.org/shifts-bff",
    REACT_APP_BITRISE_BUILD_NUMBER: process.env.REACT_APP_BITRISE_BUILD_NUMBER ?? "",
    REACT_APP_DATADOG_RUM_APPLICATION_ID: "2681f1ca-aba9-4a8e-9b52-ae4e6deb4ad3",
    REACT_APP_DATADOG_RUM_CLIENT_TOKEN: "pub88f32af388220deaa1416ca702156f46",
    REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION:
      process.env.REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION ?? "",
    REACT_APP_DOCUMENTS_SERVICE_URL: "https://apigateway.staging.clipboardhealth.org/docs/graphql",
    REACT_APP_ENVIRONMENT_NAME: DeploymentEnvironmentName.STAGING,
    REACT_APP_FIREBASE_BUNDLE_ID: "health.clipboard.worker",
    REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN: "clipboard.page.link",
    REACT_APP_GOOGLE_MAP_API: "AIzaSyC8W_0rbJCPhUHl0pf0XkWC59R4eHGpSmA",
    REACT_APP_HOME_HEALTH_API_URL: "https://home-health-api.staging.clipboardhealth.org/api",
    REACT_APP_HCP_WEB_BRAZE_KEY: "4da001ab-e194-4cd4-a175-c73f172d8a57",
    REACT_APP_HCP_WEB_BRAZE_SDK_ENDPOINT: "sdk.iad-05.braze.com",
    REACT_APP_IDENTITY_DOC_AUTOVERIFICATION_SERVICE_API_URL:
      "https://identity-doc-autoverification-service.staging.clipboardhealth.org/api",
    REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID: "60d9bf072e18760d739e5f9a",
    REACT_APP_LICENSE_MANAGER_SERVICE_URL:
      "https://apigateway.staging.clipboardhealth.org/license-manager",
    REACT_APP_LOCATION_SERVICE_URL: "https://apigateway.staging.clipboardhealth.org/location",
    REACT_APP_MUI_LICENSE_KEY:
      "9d431ac8d7aa1f4b62a9d61432d0b4a1Tz03ODUyNCxFPTE3MzEyODg5NjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
    REACT_APP_PAYMENTS_API_URL: "https://apigateway.staging.clipboardhealth.org/payment",
    REACT_APP_RADAR_SDK_PUBLISHABLE_KEY: "prj_test_pk_c3450bd48e786c9d835dc98922892915c94a3173",
    REACT_APP_SEGMENT_ANDROID_KEY: "pN53g0y3BuPEhQHED2HMHWDfRiPod2Sz",
    REACT_APP_SEGMENT_IOS_KEY: "kcntZufgUuUURpBEZUTXBC01Mg9hwV1k",
    REACT_APP_SEGMENT_KEY: "gEblLFFYPXkwJEvwiuE0KmGCbOS9t1vG",
    REACT_APP_SEGMENT_WEB_KEY: "504REOfZTAPHjRpK2TXCGlnbz9ieqPmj",
    REACT_APP_SENDBIRD_APP_ID: "F35ACEA9-2248-44DC-A448-73DCCB3FC17E",
    REACT_APP_SHIFT_REVIEW_API_URL: "https://apigateway.staging.clipboardhealth.org/reviews",
    REACT_APP_STRIPE_PUBLIC_KEY:
      "pk_test_51HrTYbCZ2LZg728WUekdiCOJFR8XRryOT2JKee8nxl15vMntnkZUQpIbEFTRNBGaHHQZjo4tWgC2LhUfbPTHDEg4000wEe7wGC",
    REACT_APP_URGENT_SHIFTS_SERVICE_URL:
      "https://apigateway.staging.clipboardhealth.org/urgent-shifts",
    REACT_APP_UX_CAM_KEY: "",
    REACT_APP_VERSION_NAME: process.env.REACT_APP_VERSION_NAME ?? "",
    REACT_APP_WEB_APP_URL: "https://hcp-webapp.staging.clipboardhealth.org",
    REACT_APP_WORKER_SERVICE_URL: "https://apigateway.staging.clipboardhealth.org/worker",
    REACT_APP_ZENDESK_JWT_MICROSERVICE_BASE_URL:
      "https://zendesk-jwt-service.scaffold.stage.clipboardstaffing.com",
    REACT_APP_ZENDESK_MESSAGING_WIDGET_URL:
      "https://static.zdassets.com/ekr/snippet.js?key=baf1c65f-72e7-4d82-b3eb-f3851c19245c",
    REACT_APP_ZENDESK_SDK_CHANNEL_KEY_ANDROID:
      "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2NsaXBib2FyZGhlYWx0aDE2MzcyNjE4NTUuemVuZGVzay5jb20vbW9iaWxlX3Nka19hcGkvc2V0dGluZ3MvMDFHMlNQWVRSM0gwQVQzS0g1VDhNTVpYOTQuanNvbiJ9",
    REACT_APP_ZENDESK_SDK_CHANNEL_KEY_IOS:
      "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2NsaXBib2FyZGhlYWx0aDE2MzcyNjE4NTUuemVuZGVzay5jb20vbW9iaWxlX3Nka19hcGkvc2V0dGluZ3MvMDFHMlNQWVRSM0gwQVQzS0g1VDhNTVpYOTQuanNvbiJ9",
    REACT_APP_ZENDESK_WEB_WIDGET_ID: "ze-snippet",
  },
  [DeploymentEnvironmentName.PROD_SHADOW]: {
    REACT_APP_ADMIN_WEB_APP_URL: "https://admin-webapp.prod-shadow.clipboardhealth.org",
    REACT_APP_API_MAIN_URL: "https://apigateway.prod-shadow.clipboardhealth.org",
    REACT_APP_ATTENDANCE_SCORE_SERVICE_URL:
      "https://apigateway.prod-shadow.clipboardhealth.org/attendance-policy",
    REACT_APP_BASE_API_URL: "https://apigateway.prod-shadow.clipboardhealth.org/api",
    REACT_APP_BFF_URL: "https://apigateway.prod-shadow.clipboardhealth.org/shifts-bff",
    REACT_APP_BITRISE_BUILD_NUMBER: process.env.REACT_APP_BITRISE_BUILD_NUMBER ?? "",
    REACT_APP_DATADOG_RUM_APPLICATION_ID: "2681f1ca-aba9-4a8e-9b52-ae4e6deb4ad3",
    REACT_APP_DATADOG_RUM_CLIENT_TOKEN: "pub88f32af388220deaa1416ca702156f46",
    REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION:
      process.env.REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION ?? "",
    REACT_APP_DOCUMENTS_SERVICE_URL:
      "https://apigateway.prod-shadow.clipboardhealth.org/docs/graphql",
    REACT_APP_ENVIRONMENT_NAME: DeploymentEnvironmentName.PROD_SHADOW,
    REACT_APP_FIREBASE_BUNDLE_ID: "health.clipboard.worker",
    REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN: "clipboard.page.link",
    REACT_APP_GOOGLE_MAP_API: "AIzaSyC8W_0rbJCPhUHl0pf0XkWC59R4eHGpSmA",
    REACT_APP_HOME_HEALTH_API_URL: "https://home-health-api.prod-shadow.clipboardhealth.org/api",
    REACT_APP_HCP_WEB_BRAZE_KEY: "4da001ab-e194-4cd4-a175-c73f172d8a57",
    REACT_APP_HCP_WEB_BRAZE_SDK_ENDPOINT: "sdk.iad-05.braze.com",
    REACT_APP_IDENTITY_DOC_AUTOVERIFICATION_SERVICE_API_URL:
      "https://identity-doc-autoverification-service.prod-shadow.clipboardhealth.org/api",
    REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID: "6470bf875947cf12f734ff1d",
    REACT_APP_LICENSE_MANAGER_SERVICE_URL:
      "https://apigateway.prod-shadow.clipboardhealth.org/license-manager",
    REACT_APP_LOCATION_SERVICE_URL: "https://apigateway.prod-shadow.clipboardhealth.org/location",
    REACT_APP_MUI_LICENSE_KEY:
      "9d431ac8d7aa1f4b62a9d61432d0b4a1Tz03ODUyNCxFPTE3MzEyODg5NjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
    REACT_APP_PAYMENTS_API_URL: "https://apigateway.prod-shadow.clipboardhealth.org/payment",
    REACT_APP_RADAR_SDK_PUBLISHABLE_KEY: "prj_test_pk_c3450bd48e786c9d835dc98922892915c94a3173",
    REACT_APP_SEGMENT_ANDROID_KEY: "pN53g0y3BuPEhQHED2HMHWDfRiPod2Sz",
    REACT_APP_SEGMENT_IOS_KEY: "kcntZufgUuUURpBEZUTXBC01Mg9hwV1k",
    REACT_APP_SEGMENT_KEY: "gEblLFFYPXkwJEvwiuE0KmGCbOS9t1vG",
    REACT_APP_SEGMENT_WEB_KEY: "504REOfZTAPHjRpK2TXCGlnbz9ieqPmj",
    REACT_APP_SENDBIRD_APP_ID: "F35ACEA9-2248-44DC-A448-73DCCB3FC17E",
    REACT_APP_SHIFT_REVIEW_API_URL: "https://apigateway.prod-shadow.clipboardhealth.org/reviews",
    REACT_APP_STRIPE_PUBLIC_KEY:
      "pk_test_51HrTYbCZ2LZg728WUekdiCOJFR8XRryOT2JKee8nxl15vMntnkZUQpIbEFTRNBGaHHQZjo4tWgC2LhUfbPTHDEg4000wEe7wGC",
    REACT_APP_URGENT_SHIFTS_SERVICE_URL:
      "https://apigateway.prod-shadow.clipboardhealth.org/urgent-shifts",
    REACT_APP_UX_CAM_KEY: "",
    REACT_APP_VERSION_NAME: process.env.REACT_APP_VERSION_NAME ?? "",
    REACT_APP_WEB_APP_URL: "https://hcp-webapp.prod-shadow.clipboardhealth.org",
    REACT_APP_WORKER_SERVICE_URL: "https://apigateway.prod-shadow.clipboardhealth.org/worker",
    REACT_APP_ZENDESK_JWT_MICROSERVICE_BASE_URL:
      "https://zendesk-jwt-service.scaffold.dev.clipboardstaffing.com",
    REACT_APP_ZENDESK_MESSAGING_WIDGET_URL:
      "https://static.zdassets.com/ekr/snippet.js?key=baf1c65f-72e7-4d82-b3eb-f3851c19245c",
    REACT_APP_ZENDESK_SDK_CHANNEL_KEY_ANDROID:
      "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2NsaXBib2FyZGhlYWx0aDE2MzcyNjE4NTUuemVuZGVzay5jb20vbW9iaWxlX3Nka19hcGkvc2V0dGluZ3MvMDFHMlNQWVRSM0gwQVQzS0g1VDhNTVpYOTQuanNvbiJ9",
    REACT_APP_ZENDESK_SDK_CHANNEL_KEY_IOS:
      "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2NsaXBib2FyZGhlYWx0aDE2MzcyNjE4NTUuemVuZGVzay5jb20vbW9iaWxlX3Nka19hcGkvc2V0dGluZ3MvMDFHMlNQWVRSM0gwQVQzS0g1VDhNTVpYOTQuanNvbiJ9",
    REACT_APP_ZENDESK_WEB_WIDGET_ID: "ze-snippet",
  },
  [DeploymentEnvironmentName.PRODUCTION]: {
    REACT_APP_ADMIN_WEB_APP_URL: "https://clipboard.health",
    REACT_APP_API_MAIN_URL: "https://api.clipboard.health",
    REACT_APP_ATTENDANCE_SCORE_SERVICE_URL: "https://api.clipboard.health/attendance-policy",
    REACT_APP_BASE_API_URL: "https://api.clipboard.health/api",
    REACT_APP_BFF_URL: "https://api.clipboard.health/shifts-bff",
    REACT_APP_BITRISE_BUILD_NUMBER: process.env.REACT_APP_BITRISE_BUILD_NUMBER ?? "",
    REACT_APP_DATADOG_RUM_APPLICATION_ID: "2681f1ca-aba9-4a8e-9b52-ae4e6deb4ad3",
    REACT_APP_DATADOG_RUM_CLIENT_TOKEN: "pub88f32af388220deaa1416ca702156f46",
    REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION:
      process.env.REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION ?? "",
    REACT_APP_DOCUMENTS_SERVICE_URL: "https://api.clipboard.health/docs/graphql",
    REACT_APP_ENVIRONMENT_NAME: DeploymentEnvironmentName.PRODUCTION,
    REACT_APP_FIREBASE_BUNDLE_ID: "health.clipboard.worker",
    REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN: "cbh.care",
    REACT_APP_GOOGLE_MAP_API: "AIzaSyA3PoA1BjIP1TGD4GiHZsBSOMqHeXE5HYE",
    REACT_APP_HOME_HEALTH_API_URL: "https://home-health-api.prod.clipboardhealth.org/api",
    REACT_APP_HCP_WEB_BRAZE_KEY: "e37d3054-b906-4925-a080-593ba55c9e2c",
    REACT_APP_HCP_WEB_BRAZE_SDK_ENDPOINT: "sdk.iad-05.braze.com",
    REACT_APP_IDENTITY_DOC_AUTOVERIFICATION_SERVICE_API_URL:
      "https://identity-doc-autoverification-service.prod.clipboardhealth.org/api",
    REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID: "60c75392e1cd230bf4f1ca30",
    REACT_APP_LICENSE_MANAGER_SERVICE_URL: "https://api.clipboard.health/license-manager",
    REACT_APP_LOCATION_SERVICE_URL: "https://api.clipboard.health/location",
    REACT_APP_MUI_LICENSE_KEY:
      "9d431ac8d7aa1f4b62a9d61432d0b4a1Tz03ODUyNCxFPTE3MzEyODg5NjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
    REACT_APP_PAYMENTS_API_URL: "https://api.clipboard.health/payment",
    REACT_APP_RADAR_SDK_PUBLISHABLE_KEY: "prj_live_pk_c0f15f432272f2430464bb1acfd82225f1909126",
    REACT_APP_SEGMENT_ANDROID_KEY: "vDtg2vA36hLD9l0wbMAlWCQGizvFCb2q",
    REACT_APP_SEGMENT_IOS_KEY: "ihkRIjiOORdGsmZ1kZcwEALTct0sovUW",
    REACT_APP_SEGMENT_KEY: "GQkYLZYfg6zvxtf8GR0i1jpi7YFf5LY3",
    REACT_APP_SEGMENT_WEB_KEY: "uKt0xDBqNRKYmHJQzTmDD1m2LMhiCGbO",
    REACT_APP_SENDBIRD_APP_ID: "7C7019BD-5389-48A2-8BCA-353823B917AF",
    REACT_APP_SHIFT_REVIEW_API_URL: "https://api.clipboard.health/reviews",
    REACT_APP_STRIPE_PUBLIC_KEY:
      "pk_live_51HrTYbCZ2LZg728WPxuzy18kjOcFTjQb8TGn4lKPA7fKLGgPMrp7NtmsSSxaSwjIpF6dhrVdlDl8ULDbeJTLexHB00MBQZsspO",
    REACT_APP_URGENT_SHIFTS_SERVICE_URL: "https://api.clipboard.health/urgent-shifts",
    REACT_APP_UX_CAM_KEY: "katzipt47zl5yc9",
    REACT_APP_VERSION_NAME: process.env.REACT_APP_VERSION_NAME ?? "",
    REACT_APP_WEB_APP_URL: "https://hcp.clipboard.health",
    REACT_APP_WORKER_SERVICE_URL: "https://api.clipboard.health/worker",
    REACT_APP_ZENDESK_JWT_MICROSERVICE_BASE_URL:
      "https://zendesk-jwt-service.scaffold.clipboardstaffing.com",
    REACT_APP_ZENDESK_MESSAGING_WIDGET_URL:
      "https://static.zdassets.com/ekr/snippet.js?key=fa1c81a4-b242-43bc-85f6-614cb14b7138",
    REACT_APP_ZENDESK_SDK_CHANNEL_KEY_ANDROID:
      "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2NsaXBib2FyZGhlYWx0aC56ZW5kZXNrLmNvbS9tb2JpbGVfc2RrX2FwaS9zZXR0aW5ncy8wMUcyU1BWRzVDNzVXNjhDWDZXSjFGUDJXWC5qc29uIn0=",
    REACT_APP_ZENDESK_SDK_CHANNEL_KEY_IOS:
      "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2NsaXBib2FyZGhlYWx0aC56ZW5kZXNrLmNvbS9tb2JpbGVfc2RrX2FwaS9zZXR0aW5ncy8wMUcyU1BOS05CNEE0SDlaR1hDNDNLN1dSNy5qc29uIn0=",
    REACT_APP_ZENDESK_WEB_WIDGET_ID: "ze-snippet",
  },
};

export function getEnvironmentConfig(environmentName: DeploymentEnvironmentName) {
  const selectedEnvironment = environmentConfigs[environmentName];
  return selectedEnvironment ?? environmentConfigs[DeploymentEnvironmentName.PRODUCTION];
}

export const environmentConfig = getEnvironmentConfig(
  process.env.REACT_APP_ENVIRONMENT_NAME as DeploymentEnvironmentName
);
